<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-autocomplete
          label="Courts"
          :items="tournament.courts"
          v-model="courts"
          multiple
          chips small-chips deletable-chips
          persistent-hint
          hint="Select up to 5 Courts"
          color="color3"
          item-color="color3"
        ></v-autocomplete>
        <v-switch label="Dark" v-model="dark" color="success"></v-switch>
        <v-switch label="Uppercase" v-model="upper" color="success"></v-switch>
      </v-col>
      <v-col cols="12">
        <v-fab-transition>
          <score-board :largeButton="true" v-if="courts.length" :courts="courts" :dark="dark" :upper="upper"></score-board>
        </v-fab-transition>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
import { mapGetters } from 'vuex'
const ScoreBoard = () => import('@/components/Scoreboards/DisplayDialogCourts.vue')

export default {
  data () {
    return {
      courts: [],
      dark: true,
      upper: true
    }
  },
  computed: {
    ...mapGetters(['tournament'])
  },
  components: {
    ScoreBoard
  }
}
// ?courts=1,2,3,4,5
</script>
